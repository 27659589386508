<template>
    <div></div>
</template>

<script>
    export default {
        name: "aliCodeUrl",
        data(){
            return{
                orderNo:""
            }
        },
        mounted() {
            var that = this;
            that.orderNo = this.$route.params.orderNo;
            if(that.orderNo != ""){
                that.getAliFormStrInfo();
            }else{
                that.utils.err("参数错误");
            }

        },
        methods:{
            getAliFormStrInfo(){
                var that = this;
                that.newApi.getAliFormStrInfo({orderNo:that.orderNo}).then((res) => {
                    if(res.isSuccess == 1){
                        document.write(res.data);
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>